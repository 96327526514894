import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MenteeRelation } from '../types/mentee-relations.types';
@Injectable({
  providedIn: 'root',
})
export class MenteeRelationService {
  constructor(private http: HttpClient) {}

  public getMenteeRelations(instance_id: string, type: string) {
    const params = new URLSearchParams({
      id: instance_id,
      type,
    });
    return this.http.get<MenteeRelation[]>(environment.apiUrl + `/mentee_relation/?` + params.toString());
  }

  public updateMenteeRelation(menteeRelation: Partial<MenteeRelation>): Observable<MenteeRelation> {
    return this.http.patch<MenteeRelation>(
      environment.apiUrl + `/mentee_relation/` + menteeRelation.id + '/',
      menteeRelation
    );
  }

  public shareAgenda(mrId: string) {
    return this.http.get<HttpResponse<any>>(environment.apiUrl + `/mentee_relation/` + mrId + `/share_agenda/`);
  }
}
